<template>
  <div class="courses flex-column">
    <!-- <v-img width="100%" height="550px" :src="career.cover">
      <div class="image-overlay d-flex flex-column justify-center px-13">
      </div>
    </v-img> -->
    <div class="content-container d-flex justify-center ">
      <div class="inner-content-container">
        <div class="pb-2">
          <span class="text-h6 font-weight-bold secondary--text">
            {{ $vuetify.lang.locales[$vuetify.lang.current].filters }}
          </span>
        </div>
        <div>
          <v-chip
            v-for="filter in filters"
            :key="filter.label"
            class="ma-2"
            :disabled="shouldBeDisabled(filter)"
            :input-value="filter.checked"
            @click="toggleFilterValue(!filter.checked, filter)"
            filter
          >
            {{filter.label}}
          </v-chip>
        </div>
      </div>
    </div>
    <div class="content-container d-flex justify-center py-6">
      <div class="inner-content-container">
        <div class="pb-2">
          <span class="text-h6 font-weight-bold secondary--text">
            {{ appliedFilters }}
          </span>
        </div>
        <div class="d-flex flex-wrap justify-space-around">
          <div v-if="loading">
            <v-progress-circular
              indeterminate
              color="amber"
            ></v-progress-circular>
          </div>
          <div v-if="!careersToDisplay.length && !loading">
            <span class="text-h6 font-weight-bold secondary--text">
              No se ha encontrado ningun curso de ese tipo
            </span>
          </div>
          <div
            v-for="career in careersToDisplay"
            :key="`career-${career.name}`"
            class="card-box-container pointer ma-2 mb-6"
          >
            <CourseBox
              :content="career.preview"
              :title="career.name"
              :description="career.shortDescription"
              @courseSelection="onCourseBoxClick(career.name)"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CourseBox from '@/components/CourseBox.vue'
import { db } from '@/firebaseSDK'

export default {
  name: 'Courses',

  data: () => ({
    careers: [],
    careersToDisplay: [],
    loading: true,
    filters: [
      {
        label: 'Presenciales',
        field: 'modalities',
        value: 'presencial',
        checked: false
      },
      {
        label: 'A distancia',
        field: 'modalities',
        value: 'a distancia',
        checked: false
      }
    ]
  }),

  components: {
    CourseBox
  },

  computed: {

    appliedFilters () {
      let filteredItems = this.filters.filter(f => f.checked).map(f => f.label)
      if (this.filters.some(f => f.checked)) {
        return `Actualmente estas viendo los cursos que son: ${filteredItems.toString()}`
      }
      return "Actualmente estas viendo todos los cursos disponibles"
    }

  },

  methods: {
    onCourseBoxClick (id) {
      this.$router.push(`/courses/${id}`)
    },

    toggleFilterValue (newVal, filter) {
      filter.checked = newVal
      let filteredCareers = this.filters.filter((f) => f.checked).reduce((acc, f) => {
        return acc.filter((career) => career[f.field] && career[f.field].includes(f.value))
      }, [...this.careers])
      this.careersToDisplay = filteredCareers
    },

    shouldBeDisabled (filter) {
      return this.filters.filter(f => f.field === filter.field && f.checked && f.value !== filter.value).length
    }
  },
  
  async mounted () {
    try {
      let careers = await db.collection("careers").where("categories", "array-contains", this.$router.currentRoute.name).get()
      let fetchedCareers = careers.docs.map((doc) => doc.data())
      this.careers = fetchedCareers
      this.careersToDisplay = fetchedCareers
    } catch (err) {
      return err
    } finally {
      this.loading = false
    }
  }
}
</script>

<style lang="scss" scoped>
.card-box-container {
  max-height: 354px;
  max-width: 400px;
  
  @media (min-width: 960px) {
    max-width: 344px;
  }
}

.inner-content-container {
  width: 90%;
}
/* .courses {
}

.pointer{
  cursor: pointer;
}

.full-width {
  width: 100%;
} */
</style>
